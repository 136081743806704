

.customer-form-section
    width 100%
    background-color #F9F9F9
.form-customer-layout
    width 100%
    @media medium
      width 100%
      padding 0
    @media small
      width 100%
      padding 0
.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
.input-transparent
  background: transparent !important;
  border-color: transparent !important;
.branch-time
  display flex
  justify-content center
  align-items center
  margin-left -12px
  margin-right -12px
  .branch
    width 50%
    padding: 12px
    @media screen and (max-width: 960px)
      width 100%
  .time
     width 50%
     padding: 12px
     @media screen and (max-width: 960px)
       width 100%
   @media screen and (max-width: 960px)
    flex-direction column

::v-deep .v-input__slot {
  background: transparent !important;
  border-color: transparent !important;
}

.scb-easy-modal
  background: transparent linear-gradient(180deg, #A700FF 0%, #5500B9 100%) 0% 0% no-repeat padding-box;
.scb-card-qrcode
  background white
  border-radius: 8px;
  border: 1px solid #00000000;
  padding: 1.5rem
.button-back-scb
  border-color: white !important

.is-desktop
  display: block
  @media tablet
    display: none
.note-form-address
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  margin-top: -32px

  font-size: 0.875rem

  > div {
	text-align: center
  }

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px



.warning-tax-invoice
  width: 100%
  padding: 8px
  border: 1px solid #ccc
  border-radius: 8px
  font-size: 14px
  margin-top: 8px
  &.studio-7
    background: #f2f2f2;
    border: none

.warning-tax-invoice-title
  font-size: 15px
  font-weight: bold

.text-alert
  color: $color-red

.text-black
  color: $color-bnn-gray-medium

.action-container
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  z-index: 8
  background-color: $color-dapp-gray-4;
  box-shadow: inset 0 .5px #7d7d7d
  margin-top: 16px

.header-modal-container
  padding: 16px 16px 0

.header-modal
  display: flex
  justify-content: center
  width: 100%

.body-modal
  padding: 16px

.header-modal-title
  font-size: 24px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.customer-form-container {
    display: grid
    grid-template-columns: repeat(1, 1fr)
    gap 21px 14px

    .span-2 {
        grid-column: span 1

        @media screen and (min-width: 768px){
            grid-column: span 2
        }
    }

    @media screen and (min-width: 768px){
        grid-template-columns: repeat(2, 1fr)
    }
}

.customer-container {
    display: flex
    flex-direction: column
    gap: 24px
    margin-bottom: 24px

    &:last-child {
        margin-bottom: 120px
    }
}

::v-deep {
    .input-compact-container {
        &:first-child {
            .input-compact {
                border-radius: 5px 0 0 5px !important;

                &:not(.v-input--is-focused) {
                    fieldset {
                        border-right: none !important;
                    }
                }
            }


        }
        &:last-child {
            .input-compact {
                border-radius: 0 5px 5px 0 !important;
            }
        }
    }

    .no-border-shadow .v-input__control {
        border: none !important;
        box-shadow: none !important;
    }
}
.seven-club-member-container {
    width: 100%
    display: flex
    justify-content: center
}

.status-scm {
    &-success {
        color: #008900
        text-align: center
    }
    &-warning {
        color: #BF4800
        text-align: center
    }
}

.content-section {
    background-color: white
    padding: 32px 0

    @media screen and (min-width: 960px) {
        padding: 64px 0
    }

    &:first-child {
        margin-bottom: 8px
    }
}
